
 .App {
   text-align: center;
   background: black;
   height: 100vh;
   max-width: 500px;
 }

 .App-logo {
   height: 40vmin;
   pointer-events: none;
 }




 .github-contact span {
   font-size: 0.85rem;
 }

 :root {
   color: white;
   --color: #42fff6
 }

 .logo p {
   margin: 0px;
   color: #999;
   font-size: 1.8rem;
   text-align: center;
   line-height: 200px;
   font-family: 'font';
   /* letter-spacing: -16px; */
   -webkit-animation: flicker 2s linear forwards infinite;
 }

 /* p:hover span {
  animation: flicker 1s linear forwards;
} */

 /* p #e {
  animation-delay: .2s;
}

p #o {
  animation-delay: .5s;
}

p #n2 {
  animation-delay: .6s;
} */

 @keyframes flicker {
   0% {
     color: #fff;
     text-shadow:
       0px 0px 5px var(--color),
       0px 0px 10px var(--color),
       0px 0px 20px var(--color),
       0px 0px 50px var(--color);
     opacity: 0.5;
     /*初始状态 透明度为0*/
   }

   50% {
     opacity: 1;
     /*初始状态 透明度为0*/
     color: #fff;
     text-shadow:
       0px 0px 5px var(--color),
       0px 0px 10px var(--color),
       0px 0px 20px var(--color),
       0px 0px 50px var(--color);
   }

   100% {
     color: #fff;
     text-shadow:
       0px 0px 5px var(--color),
       0px 0px 10px var(--color),
       0px 0px 20px var(--color),
       0px 0px 50px var(--color);
     opacity: 0.5;
     /*初始状态 透明度为0*/
   }

 }

 .logo {
   margin: 0 auto;
   user-select: none;
   /* height: 100vh; */
   /* margin-top: 40vh; */
   width: 100%;
   margin-bottom: 20px;
 }

 .center-content {
   width: 100%;
   position: absolute;
   top: 35%;
 }

 .introduce {
   font-family: 'font';
   color: white;
   opacity: 0.4;
   font-size: 20px;
   /* text-shadow: 
  0px 0px 5px var(--color),
  0px 0px 10px var(--color),
  0px 0px 20px var(--color),
  0px 0px 50px var(--color); */
 }

 .githublogo {
   fill: white;
   margin-right: 10px;
   width: 20px;
   height: 20px;
 }

 .githublogo-black {
   margin-right: 10px;
   width: 20px;
   height: 20px;
 }

 .github-contact {
   display: flex;
   cursor: pointer;
   justify-content: center;
   margin-top: 10px;
   align-items: center;
   opacity: 0.4;
   /* height: 200px; */
 }

 .github-contact-card {
   display: flex;
   cursor: pointer;
   justify-content: center;
   margin-top: 10px;
   font-size: 15px;
   align-items: center;
   /* height: 200px; */
 }

 /* p{
  color: white;
  font-family: 'P';
  font-size: 10px;

} */
 .bigcontainer {
   padding-top: 100px;
   display: flex;
   justify-content: center;
   align-items: center;
   width: 100%;
   flex-direction: column;
 }

 .bigcontainer h1 {
   color: #333;
   margin: 30px;
   font-size: 40px;
   font-weight: 1500;
   font-family: 'font';
 }

 .container {
   width: 1200px;
   position: relative;
   display: flex;
   justify-content: space-around;
 }

 .container .card {
   position: relative;


 }

 .vanta {
   min-height: 100vh;
   color: white;
   padding: 20px;
   font-size: 24px;
 }

 .container .card .face {
   width: 350px;
   height: 300px;
   transition: 0.5s;
 }

 .container .card .face.face1 {
   position: relative;
   background: rgb(247, 247, 247);
   /* display: flex;
  justify-content: center;
  align-items: center; */
   z-index: 1;
   /* overflow-y: hidden; */
   transform: translateY(100px);
 }

 .container .card .face.face1 .content {
   /* opacity: 0.2; */
   transition: 0.5s;
 }

 .container .card:hover .face.face1 .content {
   opacity: 1;
 }

 .container .card .face.face1 .content h3 {
   margin: 10px 0 0;
   padding: 0;

   color: #fff;
   text-align: center;
   font-size: 1.5em;
   font-family: 'name';
 }

 .container .card:hover .face.face1 {
   position: relative;
   background: #333;
   display: flex;
   justify-content: center;
   align-items: center;
   z-index: 1;
   transform: translateY(0px);
   transition: 0.75s;
 }

 .container .card:hover .face.face1 .role1 {
   transition: 5.75s;
 }

 .container .card .face.face2 {
   position: relative;


   display: flex;
   justify-content: center;
   align-items: center;
   padding: 20px;
   box-sizing: border-box;
   box-shadow: 0 11px 20px rgba(0, 0, 0, 0.1);
   transform: translateY(-100px);

 }

 .container .card:hover .face.face2 {
   transform: translateY(0);
 }

 .container .card:hover .role {
   width: 170px;
   transition: 0.5s;
 }

 .container .card .role {
   width: 155PX;
 }

 .container .card:hover .role2 {
   width: 200px;
   transition: 0.5s;
 }

 .container .card .role2 {
   width: 170PX;
 }

 .container .card:hover .role1 {
   width: 350px;
   transition: 1.5s;
 }

 .container .card .role1 {
   width: 350px;
   /* height: 350px; */
 }